/**
 * Takes a phone number string and attempts to convert it to e.164 format.
 */
export function toE164(phoneNumber: string): string {
  const match = phoneNumber.match(/\d/g) || []
  return `+1${match.join('')}`
}

/**
 * Takes an e.164 formatted number string and converts it to a 10 digit number string.
 */
export function fromE164(formattedPhoneNumber: string): string {
  return formattedPhoneNumber.slice(2)
}

/**
 * Somewhat naive check to see if a phone number is valid.
 *
 * @param phoneNumber Phone number string
 * @returns Whether or not a phone number is valid
 */
export function checkValidPhoneNumber(phoneNumber: string): boolean {
  const allDigits = /\d/g.test(phoneNumber)
  const length = phoneNumber.length

  if (allDigits && (length === 10 || length === 11)) return true

  return false
}

/**
 * Turns an E164 phone number into a formatted number.
 * @param phoneNumber E164 phone number
 */
export function formatE164PhoneNumber(phoneNumber: string): string {
  // Remove any non-digit characters from the phone number
  const cleanNumber = phoneNumber.replace(/\D/g, '')

  // Extract the country code and the remaining digits
  // const countryCode = cleanNumber.substring(0, 1)
  const areaCode = cleanNumber.substring(1, 4)
  const mainDigits = cleanNumber.substring(4)

  // Format the phone number as (xxx) xxx-xxxx
  const formattedNumber = `(${areaCode}) ${mainDigits.substring(
    0,
    3
  )}-${mainDigits.substring(3)}`

  return formattedNumber
}
