import { DEFAULT_ICON_SIZE, getSize, IconProps } from './lib'

/**
 * ExclamationFilled SVG Icon
 */
const ExclamationFilledIcon: React.FC<IconProps> = ({
  size = DEFAULT_ICON_SIZE,
  className = '',
}) => {
  const iconSize = getSize(size)

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={iconSize}
      height={iconSize}
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M9 18C13.9711 18 18 13.9711 18 9C18 4.02891 13.9711 0 9 0C4.02891 0 0 4.02891 0 9C0 13.9711 4.02891 18 9 18ZM9 4.5C9.46758 4.5 9.84375 4.87617 9.84375 5.34375V9.28125C9.84375 9.74883 9.46758 10.125 9 10.125C8.53242 10.125 8.15625 9.74883 8.15625 9.28125V5.34375C8.15625 4.87617 8.53242 4.5 9 4.5ZM10.125 12.375C10.125 12.9973 9.62227 13.5 9 13.5C8.37773 13.5 7.875 12.9973 7.875 12.375C7.875 11.7527 8.37773 11.25 9 11.25C9.62227 11.25 10.125 11.7527 10.125 12.375Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ExclamationFilledIcon
