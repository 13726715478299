import { PropsWithChildren } from 'react'

import styles from './layout-banner.module.css'

export type LayoutBannerType = React.FC<PropsWithChildren>

/**
 * Sticky banner across the top of the Layout. Does not currently work well with < desktop screens.
 */
const LayoutBanner: LayoutBannerType = ({ children }) => {
  return <div className={styles.banner}>{children}</div>
}

export default LayoutBanner
