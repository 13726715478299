import cls from 'classnames'

import styles from './form-group.module.css'

export interface FormGroupProps {
  /**
   * Optional label for form group.
   */
  label?: string | React.ReactNode
  className?: string
  children: React.ReactNode
}

/**
 * Wrapper with optional formatted label for form elements.
 */
const FormGroup = ({ className, children, label }: FormGroupProps) => {
  return (
    <div className={cls(styles.formGroup, className)}>
      {label ? <label>{label}</label> : null}
      {children}
    </div>
  )
}

export default FormGroup
