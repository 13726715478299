import cls from 'classnames'
import Head from 'next/head'

import LayoutBanner, { LayoutBannerType } from './layout-banner'
import LayoutContent, { LayoutContentType } from './layout-content'

type PageDetailsProps = {
  title: string
  description: string
}

type Props = {
  unauthed?: boolean
  banner?: React.ReactElement<LayoutBannerType, typeof LayoutBanner>
  children?: React.ReactNode
  className?: string
  mobileCta?: React.ReactNode
  pageDetails: PageDetailsProps

  /**
   * narrow: 800px
   * medium: 1100px
   * wide: full width
   */
  width?: 'narrow' | 'medium' | 'wide'
}

type LayoutType = React.FC<Props> & {
  Banner: LayoutBannerType
  Content: LayoutContentType
}

// TODO: this should become our unauthed layout
const Layout: LayoutType = ({
  unauthed,
  banner,
  children,
  className,
  pageDetails,
  width,
}) => {
  const { title, description } = pageDetails

  let widthClassNames: string
  if (width === 'wide') {
    widthClassNames = 'relative container max-w-full'
  } else if (width === 'medium') {
    widthClassNames = 'relative container max-w-[1100px] mx-auto'
  } else {
    widthClassNames = 'relative container max-w-[800px] mx-auto'
  }

  const contents = (
    <>
      {banner}
      <main className={cls(widthClassNames, className ?? '', {})}>
        {children}
      </main>
    </>
  )

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="shortcut icon" href="/images/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/images/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/images/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/images/favicon-16x16.png"
        />
      </Head>

      {unauthed ? contents : null}
    </>
  )
}

Layout.Banner = LayoutBanner
Layout.Content = LayoutContent

export default Layout
