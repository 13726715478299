export const ERROR_MESSAGE_TYPES = {
  // Phone number
  PHONE_FORMAT: 'PHONE_FORMAT',
  PHONE_LENGTH: 'PHONE_LENGTH',
  PHONE_OTHER: 'PHONE_OTHER',
  PHONE_NOT_FOUND: 'PHONE_NOT_FOUND',
  PHONE_REQUIRED: 'PHONE_REQUIRED',

  // Email
  EMAIL_OTHER: 'EMAIL_OTHER',
  EMAIL_NOT_FOUND: 'EMAIL_NOT_FOUND',
  EMAIL_INVALID: 'EMAIL_INVALID',
  EMAIL_REQUIRED: 'EMAIL_REQUIRED',

  // Otp
  FAILED_VERIFICATION: 'FAILED_VERIFICATION',
  TOO_MANY_INCORRECT_TRIES: 'TOO_MANY_INCORRECT_TRIES',
}

export const ERROR_MESSAGES = {
  PHONE_FORMAT: 'Enter a valid phone number',
  PHONE_LENGTH: '10-digit number is required',
  PHONE_OTHER: `SMS failed to send. Double check you have entered a mobile phone number or`,
  PHONE_NOT_FOUND: `We couldn’t find your account. Try signing in with your email or`,
  PHONE_REQUIRED: 'Phone number is required',

  EMAIL_NOT_FOUND: `We couldn’t find your account. Try signing in with your phone number or`,
  EMAIL_OTHER: `Email failed to send. Double check the email entered or`,
  EMAIL_INVALID: 'Enter a valid email address',
  EMAIL_REQUIRED: 'Email is required',

  FAILED_VERIFICATION: 'Invalid code. Try again or resend a new code',
  TOO_MANY_INCORRECT_TRIES:
    'Too many incorrect sign in attempts. Reach out to support@buildforce.com.',
}
