import { NextApiRequest, NextApiResponse } from 'next'

import { startChallenge } from '../../../utils/authenticate'

export async function startChallengeProcess({
  shortToken,
  phone,
  email,
}: {
  shortToken?: string
  phone?: string
  email?: string
}): Promise<{ successful: boolean; shouldRedirect?: boolean }> {
  const params = new URLSearchParams()
  !!shortToken && params.append('a', shortToken)
  !!phone && params.append('phone', phone)
  !!email && params.append('email', email)

  const r = await fetch(`/api/authenticate/challenge?${params.toString()}`, {
    method: 'POST',
  })

  if (r.ok) {
    return await r.json()
  } else {
    const error = {
      status: r.status,
      message: r.status === 500 ? 'Error starting challenge' : r.statusText,
    }

    throw error
  }
}

export default async function handler(
  req: NextApiRequest,
  res: NextApiResponse<{
    successful: boolean
    shouldRedirect?: boolean
    message?: string
  }>
) {
  try {
    if (!req.cookies) throw new Error('Cookies not found')
    if (
      (req.query.a || req.query.phone || req.query.email) &&
      req.method === 'POST'
    ) {
      const response = await startChallenge(req, res)

      if ('token' in response) {
        return res.status(200).json({ successful: !!response.token })
      } else if ('message' in response) {
        return res.status(response.status).send({
          successful: false,
          message: response.message,
        })
      }
    } else {
      return res.status(400).send({
        successful: false,
        message: 'missing required a parameter or POST method',
      })
    }
  } catch (error) {
    console.log('Oh no', error)
    res.status(500).send({
      message:
        error instanceof Error ? error.message : 'Unknown error occurred',
      successful: false,
    })
  }
}
