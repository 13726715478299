import cls from 'classnames'
import { PropsWithChildren } from 'react'

import styles from './layout-content.module.css'

export type LayoutContentType = React.FC<
  { noPadding?: boolean } & PropsWithChildren
>

const LayoutContent: LayoutContentType = ({ children, noPadding }) => {
  return (
    <div className={cls(styles.content, { [styles.noPadding]: noPadding })}>
      {children}
    </div>
  )
}

export default LayoutContent
