import cls from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { parseCookies } from 'nookies'
import { useState, useCallback, useEffect } from 'react'
import { LoaderIcon } from 'react-hot-toast'

import { useAnalytics } from '../../../../hooks/use-analytics'
import { usePrevious } from '../../../../hooks/util/use-previous'
import { verifyOtp } from '../../../../pages/api/authenticate'
import { startChallengeProcess } from '../../../../pages/api/authenticate/challenge'
import BuildforceLogo from '../../../../public/buildforce_logo-lockup.svg'
import {
  ACCESS_TOKEN_COOKIE_PREFIX,
  REFRESH_TOKEN_COOKIE_PREFIX,
  EMPLOYER_USER_COOKIE_PREFIX,
  CHALLENGE_SESSION_PREFIX,
} from '../../../../utils/authenticate'
import { validateEmail } from '../../../../utils/email'
import { toE164 } from '../../../../utils/to-e164'
import BodySmall from '../../../base/data-display/typography/body-small/body-small'
import Button, { ButtonVariant } from '../../../base/inputs/button/button'
import Input, { InputChangePayload } from '../../../base/inputs/input/input'
import Layout from '../../../base/layout/unauthed-layout'
import { OtpForm } from '../../../patterns/otp-input/otp-form'

import { ERROR_MESSAGE_TYPES, ERROR_MESSAGES } from './errors'
import styles from './sign-in-view.module.css'

const DEFAULT_LANDING_PAGE = '/projects'

const PARAMS = {
  TOKEN: 'a',
  NEXT: 'next',
  ACTION: 'action',
  OTP: 'otp',
  AUTH: 'auth',
}

export const SignInView = () => {
  const router = useRouter()
  const analytics = useAnalytics()

  const cookies = parseCookies()

  const otpCode = router.query[PARAMS.OTP] as string
  const authType = (router.query[PARAMS.AUTH] as string) || 'phone'

  const [formState, setFormState] = useState({
    phoneNumber: '',
    email: '',
    otp: '',
  })
  const [autoVerify, setAutoVerify] = useState(!!otpCode)
  const [checkingAccess, setCheckingAccess] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [hasValidEmail, setHasValidEmail] = useState(false)
  const [hasValidPhone, setHasValidPhone] = useState(false)
  const [initialLoad, setInitialLoad] = useState(true)
  const [isLoading, setisLoading] = useState(false)
  const [signInTries, setSignInTries] = useState(0)

  const partialPhoneNumber = formState.phoneNumber.slice(-4)
  const allowSubmission = signInTries <= 3
  const prevAction = usePrevious(router.query[PARAMS.ACTION])

  const resetState = (resetForm?: boolean) => {
    setAutoVerify(false)
    setError(null)
    setHasValidEmail(false)
    setHasValidPhone(false)
    setInitialLoad(true)
    setisLoading(false)
    setSignInTries(0)

    if (resetForm) {
      setFormState({
        phoneNumber: '',
        email: '',
        otp: '',
      })
    }
  }

  const resetSignInState = useCallback(() => {
    resetState(true)
  }, [])

  const getRedirectWithoutParam = useCallback(
    (param: string | string[]) => {
      const url = new URL(`http://_${router.query[PARAMS.NEXT]}`)
      const params = url.searchParams

      if (Array.isArray(param)) {
        param.forEach((p) => params.delete(p))
      } else {
        params.delete(param)
      }

      return {
        pathname: url.pathname,
        query: params.toString(),
      }
    },
    [router.query]
  )

  // Check for either x-access or x-refresh tokens
  // and redirect the user to the app
  useEffect(() => {
    if (initialLoad) {
      // Sign in page should always have an action
      if (!router.query[PARAMS.ACTION]) {
        router.replace({
          query: {
            ...router.query,
            [PARAMS.ACTION]: 'send',
            next: router.query[PARAMS.NEXT] ?? DEFAULT_LANDING_PAGE,
          },
        })
      }

      const token = Object.keys(cookies).find(
        (key) =>
          key.startsWith(ACCESS_TOKEN_COOKIE_PREFIX) ||
          key.startsWith(REFRESH_TOKEN_COOKIE_PREFIX)
      )

      const employerUser = Object.keys(cookies).find((key) =>
        key.startsWith(EMPLOYER_USER_COOKIE_PREFIX)
      )

      // Authenticate checks for employerUserJson in order to get a token
      if (token && employerUser) {
        if (router.query[PARAMS.TOKEN]) {
          const next = getRedirectWithoutParam(PARAMS.TOKEN)
          router.replace(next)
        } else {
          const next = router.query[PARAMS.NEXT]
          router.replace((next as string) || DEFAULT_LANDING_PAGE)
        }
      } else {
        setCheckingAccess(false)
      }
    }
  }, [getRedirectWithoutParam, initialLoad, router, cookies])

  /* These two effects work together to reset the page */
  useEffect(() => {
    if (
      router.query[PARAMS.ACTION] === 'verify' &&
      !otpCode &&
      !hasValidPhone &&
      !hasValidEmail
    ) {
      router.replace({
        query: { ...router.query, [PARAMS.ACTION]: 'send' },
      })
    }
  }, [router, hasValidPhone, hasValidEmail, otpCode])

  useEffect(() => {
    if (prevAction === 'verify' && router.query[PARAMS.ACTION] === 'send') {
      resetState()
    }
  }, [prevAction, router.query, resetSignInState])

  /*
  To avoid automatically sending an verification code on load,
    we do a quick redirect updating the query param to send,
    then back to verify.
  This helps avoid things like sending the code when unfurling URLs
    and other potentially errant triggers.
  */
  useEffect(() => {
    const sendChallenge = async () => {
      // There are three ways to get into the app: a short token magic link, an email magic link, or by OTP via phone number
      const startVerificationProcess =
        !otpCode &&
        (!!router.query[PARAMS.TOKEN] || hasValidPhone || hasValidEmail)

      // If the user has a magic link, we just want to show a loading state while we auth them in
      if (!startVerificationProcess) {
        setInitialLoad(false)
      }

      try {
        if (
          (router.query[PARAMS.ACTION] === 'send' ||
            router.query[PARAMS.ACTION] === 'resend') &&
          startVerificationProcess
        ) {
          // If the user tries to resend the code, we should let them try to sign in again
          if (router.query[PARAMS.ACTION] === 'resend' && signInTries > 3) {
            setSignInTries(0)
          }

          // Show loading state when the user has submitted
          if (hasValidPhone || hasValidEmail) {
            setisLoading(true)
          }

          const shortToken = router.query[PARAMS.TOKEN] as string

          const payload =
            authType === 'phone'
              ? {
                  phone: hasValidPhone
                    ? toE164(formState.phoneNumber)
                    : undefined,
                }
              : { email: formState.email }

          // If the user is signing in with email, we want to save the next param to a cookie
          // So we can redirect them after they have authenticated via magic link
          if (authType === 'email') {
            const redirect = router.query
            const next = redirect[PARAMS.NEXT]

            // Save to cookies to read later, expires after 3 minutes
            if (next) {
              document.cookie = `next=${next};max-age=180;path=/`
            }
          }

          analytics.track('Sign In Submit', {
            type: authType,
          })

          const result = await startChallengeProcess(
            shortToken
              ? {
                  shortToken: router.query[PARAMS.TOKEN] as string,
                }
              : payload
          )

          // This success path can only be accessed via a magic link (valid short token)
          if (result.successful) {
            // We drop the short token once we have access to the app
            const next = getRedirectWithoutParam(PARAMS.TOKEN)

            router.replace(next)
          } else {
            // Challenge has been kicked off, now the user must submit their OTP

            if (setInitialLoad) setInitialLoad(false)

            router.replace({
              query: { ...router.query, [PARAMS.ACTION]: 'verify' },
            })

            setisLoading(false)
          }
        }
      } catch (error) {
        if (router.query[PARAMS.TOKEN]) {
          // If there's an error and a short token provided, it's most likely invalid
          // Just have the user sign via phone number
          resetSignInState()
          const next = getRedirectWithoutParam(PARAMS.TOKEN)
          router.replace(next)
        } else {
          if (formState.phoneNumber) {
            analytics.track('Error Signing In', {
              phoneNumber: formState.phoneNumber,
            })
          }
          setError(
            // @ts-expect-error Not sure best way check for existence of the stats property on unknown
            error.status == 404
              ? authType === 'email'
                ? ERROR_MESSAGE_TYPES.EMAIL_NOT_FOUND
                : ERROR_MESSAGE_TYPES.PHONE_NOT_FOUND
              : authType === 'email'
              ? ERROR_MESSAGE_TYPES.EMAIL_OTHER
              : ERROR_MESSAGE_TYPES.PHONE_OTHER
          )
          setisLoading(false)
        }
      }
    }

    sendChallenge()
  }, [
    analytics,
    checkingAccess,
    formState,
    getRedirectWithoutParam,
    hasValidPhone,
    hasValidEmail,
    signInTries,
    router,
    router.query,
    authType,
    otpCode,
    error,
    resetSignInState,
  ])

  const getErrorMessage = () => {
    if (!error) return undefined

    if (
      error &&
      [
        ERROR_MESSAGE_TYPES.PHONE_NOT_FOUND,
        ERROR_MESSAGE_TYPES.PHONE_OTHER,
        ERROR_MESSAGE_TYPES.EMAIL_NOT_FOUND,
        ERROR_MESSAGE_TYPES.EMAIL_OTHER,
      ].includes(error)
    ) {
      const contactUsLink = (
        <Button
          className={cls('intercom_launcher', styles.contactUs)}
          variant={ButtonVariant.WithChildren}
        >
          <BodySmall color="error" weight="bold">
            contact us
          </BodySmall>
        </Button>
      )

      return (
        <div className={styles.withContactUsLink}>
          <BodySmall color="error" weight="bold">
            {ERROR_MESSAGES[error as keyof typeof ERROR_MESSAGES]}
          </BodySmall>
          &nbsp;
          {contactUsLink}
        </div>
      )
    }

    return ERROR_MESSAGES[error as keyof typeof ERROR_MESSAGES]
  }

  const validatePhoneInput = useCallback(() => {
    const { phoneNumber } = formState

    if (!phoneNumber) {
      setError(ERROR_MESSAGE_TYPES.PHONE_REQUIRED)
      return false
    }
    if (phoneNumber && toE164(phoneNumber).length > 12) {
      setError(ERROR_MESSAGE_TYPES.PHONE_LENGTH)
      return false
    }
    if (phoneNumber && toE164(phoneNumber).length !== 12) {
      setError(ERROR_MESSAGE_TYPES.PHONE_FORMAT)
      return false
    }

    return true
  }, [formState])

  const validateEmailInput = useCallback(() => {
    const { email } = formState

    if (!email) {
      setError(ERROR_MESSAGE_TYPES.EMAIL_REQUIRED)
      return false
    }

    if (!validateEmail(email)) {
      setError(ERROR_MESSAGE_TYPES.EMAIL_INVALID)
      return false
    }

    return true
  }, [formState])

  const handleChange = (e: InputChangePayload) => {
    if (error) setError(null)

    if (e.name === 'phoneNumber') setHasValidPhone(false)
    if (e.name === 'email') setHasValidEmail(false)

    setFormState((prev) => ({ ...prev, [e.name]: e.value }))
  }

  const handleOtpSubmit = useCallback(
    async (otp: string) => {
      analytics.track('OTP Submitted')

      if (!isLoading) setisLoading(true)

      try {
        setSignInTries(signInTries + 1)

        const response = await verifyOtp(otp)

        // On successful verification, route the user to the requested page
        if (response.successful) {
          analytics.track('OTP Verification Successful', {
            signInTries,
          })

          // If there is a next cookie, redirect the user to that page
          const next = Object.keys(cookies).find((key) =>
            key.startsWith('next')
          )

          if (next) {
            const nextValue = cookies[next]
            // Delete the cookie
            document.cookie = `${next}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`
            router.replace(nextValue)
          } else {
            const { next } = router.query

            // Drop the short token if it exists
            if (router.query[PARAMS.TOKEN]) {
              const next = getRedirectWithoutParam([PARAMS.TOKEN, PARAMS.OTP])

              router.replace(next)
            } else {
              const redirect = next || DEFAULT_LANDING_PAGE
              router.replace(redirect as string)
            }
          }
        } else {
          analytics.track('OTP Verification Failed', { signInTries })
          setError(
            signInTries < 3
              ? ERROR_MESSAGE_TYPES.FAILED_VERIFICATION
              : ERROR_MESSAGE_TYPES.TOO_MANY_INCORRECT_TRIES
          )
          setisLoading(false)
        }
      } catch (error) {
        console.error('Error', error)
      }
    },
    [
      analytics,
      signInTries,
      router,
      getRedirectWithoutParam,
      isLoading,
      cookies,
    ]
  )

  // Automatically submit the OTP code if it's in the URL
  useEffect(() => {
    if (autoVerify) {
      setisLoading(true)
      setAutoVerify(false)

      const challengeCookie = Object.keys(cookies).find((key) =>
        key.startsWith(CHALLENGE_SESSION_PREFIX)
      )

      // If there's no challenge cookie, we can't verify the OTP
      if (!challengeCookie) {
        analytics.track('Challenge Cookie Not Found. Cannot verify OTP.', {
          otpCode,
        })

        router.replace({
          query: {
            [PARAMS.ACTION]: 'send',
            next: router.query[PARAMS.NEXT] ?? DEFAULT_LANDING_PAGE,
          },
        })

        return
      }

      const authenticate = async () => {
        await handleOtpSubmit(otpCode)
      }

      authenticate()
    }
  }, [otpCode, handleOtpSubmit, autoVerify, analytics, cookies, router])

  const handleResendCode = () => {
    // Restarts authentication
    analytics.track('Resend OTP Button Clicked')

    setisLoading(true)

    router.replace({
      query: { ...router.query, [PARAMS.ACTION]: 'resend' },
    })

    setisLoading(false)
    setError(null)
  }

  const renderIdentificationStep = () => {
    const mobilePhoneInput = (
      <Input
        label={<strong>Mobile phone number</strong>}
        error={getErrorMessage()}
        value={formState.phoneNumber}
        name="phoneNumber"
        onChange={handleChange}
        type="tel"
        placeholder="(000) 000-0000"
      />
    )

    const emailInput = (
      <Input
        label={<strong>Email</strong>}
        error={getErrorMessage()}
        value={formState.email}
        name="email"
        onChange={handleChange}
        type="text"
        placeholder="name@work-domain.com"
      />
    )
    const switchAuthTypeComponent = (
      <>
        <div className={styles.divider}>
          <h6 className={styles.or}>or</h6>
        </div>
        <Button
          variant={ButtonVariant.Secondary}
          className={styles.white}
          onClick={() => {
            resetState()
            router.replace({
              pathname: '/signin',
              query: {
                ...router.query,
                [PARAMS.ACTION]: 'send',
                auth: authType === 'email' ? 'phone' : 'email',
              },
            })
          }}
        >
          Sign in with {authType === 'email' ? 'phone number' : 'email'}
        </Button>
      </>
    )

    const phoneCaption =
      'Enter the phone number associated with your account. You will receive a 5-digit code via SMS.'
    const emailCaption =
      'Enter the email associated with your account. You will be emailed a link that can be used to sign into your account.'

    return (
      <div className={styles.signIn}>
        <div>
          <h4>Sign in</h4>
          <p>{authType === 'email' ? emailCaption : phoneCaption}</p>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault()

            if (authType === 'phone') {
              setHasValidPhone(validatePhoneInput())
            } else {
              setHasValidEmail(validateEmailInput())
            }
          }}
          className={styles.signInForm}
        >
          {authType === 'email' ? emailInput : mobilePhoneInput}
          <Button
            analyticsPrefix="Sign In Submit"
            label="Continue"
            type="submit"
            disabled={isLoading || !!error}
            isLoading={isLoading}
          />
          {switchAuthTypeComponent}
        </form>
      </div>
    )
  }

  const trySigningInAnotherWayLink = (authType?: string) => (
    <Button
      variant={ButtonVariant.Link}
      className={styles.switchSignInMethod}
      analyticsPrefix="Try Signing In Another Way"
      onClick={() => {
        resetState()
        router.replace({
          pathname: '/signin',
          query: {
            ...router.query,
            [PARAMS.ACTION]: 'send',
            auth: authType,
          },
        })
      }}
    >
      Try signing in another way
    </Button>
  )

  const renderEmailVerificationStep = () => {
    const email = <strong>{formState.email}</strong>
    return (
      <div className={styles.signIn}>
        <div>
          <h4>Check your email</h4>
          <div className={styles.emailCaption}>
            <BodySmall color="white">
              We sent an email to you at&nbsp;
            </BodySmall>
            <BodySmall color="white">{email}.</BodySmall>
            <BodySmall color="white">
              &nbsp;Click on the link provided in the email to sign into
              Buildforce. You may safely close out of this page.
            </BodySmall>
          </div>
          <br />
          <BodySmall color="white">
            If you can’t locate the email, please check your spam or junk
            folder.
          </BodySmall>
        </div>
        <div className={styles.divider} />
        {trySigningInAnotherWayLink('email')}
      </div>
    )
  }

  const renderMobileVerificationStep = () => (
    <div className={styles.signIn}>
      <div>
        <h4>Confirm it’s you</h4>
        <BodySmall color="white">
          We sent a 5-digit verification code to your&nbsp;
          <strong>phone ending in {partialPhoneNumber}</strong>. Enter the code
          below to sign in.
        </BodySmall>
      </div>
      {isLoading ? (
        <div className={styles.loading}>
          <LoaderIcon />
        </div>
      ) : (
        <OtpForm
          codeLength={5}
          disabled={!allowSubmission}
          error={getErrorMessage()}
          label="5 digit code"
          onChange={() => {
            if (error) {
              setError(null)
            }
          }}
          onSubmit={handleOtpSubmit}
        />
      )}

      <div className={styles.resend}>
        <BodySmall color="secondary">This code expires in 5 minutes</BodySmall>
        <div className={styles.divider} />
        <div>
          <Button
            analyticsPrefix="Resend OTP Code"
            variant={ButtonVariant.Link}
            label="Resend code"
            onClick={handleResendCode}
            disabled={isLoading}
          />
          {trySigningInAnotherWayLink('phone')}
        </div>
      </div>
    </div>
  )

  return (
    <Layout
      unauthed
      banner={
        <div className={styles.top}>
          <Link
            href={{
              pathname: DEFAULT_LANDING_PAGE,
            }}
          >
            <Image
              src={BuildforceLogo}
              width={118.31}
              height={20}
              alt="Buildforce logo"
            />
          </Link>
          <Button
            variant={ButtonVariant.Link}
            className="intercom_launcher"
            onClick={() =>
              analytics.track('Sign In Get Help Verification Link Clicked:', {
                phone: formState.phoneNumber,
              })
            }
          >
            Get help
          </Button>
        </div>
      }
      pageDetails={{
        title: 'Sign in to Buildforce',
        description: '',
      }}
      width="wide"
    >
      <div className={styles.container}>
        {!!otpCode ||
        initialLoad ||
        checkingAccess ||
        router.query[PARAMS.ACTION] === 'resend' ? (
          <LoaderIcon />
        ) : (
          <>
            {router.query[PARAMS.ACTION] === 'verify' &&
            (hasValidEmail || hasValidPhone)
              ? authType === 'email'
                ? renderEmailVerificationStep()
                : renderMobileVerificationStep()
              : renderIdentificationStep()}
          </>
        )}
      </div>
    </Layout>
  )
}
