import { NextPage } from 'next'

import SignInView from '../../components/scenes/views/sign-in-view'

export function getServerSideProps() {
  return {
    props: {},
  }
}

const SignInPage: NextPage = () => {
  return <SignInView />
}

export default SignInPage
