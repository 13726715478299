import { NextApiRequest, NextApiResponse } from 'next'
import nookies from 'nookies'

import {
  CHALLENGE_SESSION_PREFIX,
  clearAuthenticationCookies,
  EMPLOYER_USER_COOKIE_PREFIX,
  setAuthenticationCookies,
  verifyOtpChallenge,
} from '../../../utils/authenticate'
import fetcher from '../../../utils/fetcher'

export function verifyOtp(otp: string): Promise<{ successful: boolean }> {
  const params = new URLSearchParams()
  params.append('otp', otp)

  return fetcher(`/api/authenticate?${params.toString()}`)
}

export default async function handler(
  req: NextApiRequest,
  res: NextApiResponse<{
    successful: boolean
    message?: string
    retry?: boolean
  }>
) {
  try {
    if (!req.cookies) throw new Error('Cookies not found')
    const { cookies } = req
    let username = ''
    let employerId = ''

    const employerUserCookie = cookies[EMPLOYER_USER_COOKIE_PREFIX]

    if (employerUserCookie) {
      const employerUserJson = JSON.parse(employerUserCookie)

      username = employerUserJson.phone
      employerId = employerUserJson.employer_id
    }

    const sessionCookieName = Object.keys(cookies).find((cookieName) =>
      cookieName.startsWith('x-session')
    )
    if (!sessionCookieName) {
      throw new Error('Failed to find session cookiename')
    }
    const sessionCookie = cookies[sessionCookieName]

    if (!sessionCookie) {
      throw new Error(`Failed to cookie with key: ${sessionCookieName}`)
    }
    const response = await verifyOtpChallenge(
      req.query.otp as string,
      sessionCookie,
      username
    )

    // Set the new tokens
    if ('tokens' in response) {
      const { accessToken, expiresIn, refreshToken } = response.tokens
      const { user } = response

      clearAuthenticationCookies(req, res)

      setAuthenticationCookies(
        { res },
        employerId,
        refreshToken,
        accessToken,
        expiresIn,
        user
      )

      return res.status(200).json({ successful: true })
    } else if ('sessionToken' in response) {
      const { sessionToken } = response
      if (!sessionToken) {
        console.error('No session token found in response', response)
        throw new Error('No sessionToken found in response')
      }

      nookies.set({ res }, CHALLENGE_SESSION_PREFIX, sessionToken, {
        maxAge: 60 * 2, // 2 minutes
        path: '/',
      })
      return res.status(200).json({ successful: false })
    } else {
      return res.status(400).send(response)
    }
  } catch (error) {
    res.status(400).send({
      successful: false,
      message: error instanceof Error ? error.message : 'Unknown error',
    })
  }
}
