import cls from 'classnames'
import {
  ChangeEvent,
  FocusEvent,
  HTMLInputTypeAttribute,
  ReactNode,
} from 'react'

import LabelMedium from '../../data-display/typography/label-medium/label-medium'
import { ErrorMessage } from '../error'

import styles from './input.module.css'

export interface InputChangePayload {
  /**
   * HTML name key of the element
   */
  name: string
  /**
   * Value of the input
   */
  value: string
}

export interface InputProps {
  /**
   * Is the Input field disabled?
   */
  disabled?: boolean
  /*
   * Should the input automatically focus on render?
   */
  autoFocus?: boolean
  /**
   * Always display the value, even if falsy.
   */
  alwaysShowValue?: boolean
  /**
   * Optional classname
   */
  className?: string
  /**
   * By default, we want to capture data in Hotjar
   * You can disable it by passing `true`
   * https://help.hotjar.com/hc/en-us/articles/115015563287
   */
  disableHjData?: boolean
  /**
   * Is there an error message associated with this input?
   */
  error?: ReactNode
  /**
   * Icon to display
   */
  icon?: React.ReactNode
  /**
   * Label to display above the input.
   */
  label?: string | JSX.Element
  /**
   * Unique name of the form input. Used for form keys.
   */
  name: string
  /**
   * Function to call when the input value changes.
   */
  onChange: (value: InputChangePayload) => void
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void
  /**
   * Function to call when the user releases a key.
   */
  onKeyUp?: (value: KeyboardEvent) => void
  /**
   * Function to call when the user presses a key.
   */
  onKeyDown?: (value: KeyboardEvent) => void
  /**
   * Function to call when the user clicks the input.
   */
  onClick?: () => void
  /**
   * Should pass entire event when `onChange` is called?
   */
  passEntireEvent?: boolean
  /**
   * Text to render in the absence of a value.
   */
  placeholder?: string
  /**
   * Inline styling to be applied to the input wrapper.
   */
  style?: {
    [key: string]: string
  }
  /**
   * What type of html input is this?
   */
  type: HTMLInputTypeAttribute
  /**
   * Value of the input
   */
  value?: string | number
  /**
   * Should we check the validity of the target value before calling onChange?
   */
  shouldValidate?: boolean
  other?: any
}

const Input: React.FC<InputProps> = ({
  alwaysShowValue,
  autoFocus,
  className,
  disabled,
  disableHjData = false,
  error = '',
  icon,
  label,
  name,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  onKeyUp,
  onClick,
  other,
  passEntireEvent,
  placeholder,
  shouldValidate,
  style,
  type = 'text',
  value,
}) => {
  const _value = !alwaysShowValue && !value ? '' : value

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (passEntireEvent) return onChange(e)

    return (
      (shouldValidate ? e.target.validity.valid : true) &&
      onChange({ name, value: e.target.value })
    )
  }

  const renderInput = () => (
    <div className="inputControl">
      {icon ? <div className="inputIcon">{icon}</div> : null}
      <input
        data-hj-allow={!disableHjData}
        disabled={disabled}
        autoFocus={autoFocus}
        className={cls('input', className, {
          error,
          hasIcon: icon,
        })}
        name={name}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={handleChange}
        onKeyDown={onKeyDown ? onKeyDown : undefined}
        onKeyUp={onKeyUp ? onKeyUp : undefined}
        onClick={onClick ? onClick : undefined}
        placeholder={placeholder}
        type={type}
        value={_value}
        {...other}
      />
      {!!error && (
        <div className={styles.errorContainer}>
          {typeof error === 'string' ? (
            <ErrorMessage errorMessage={error} />
          ) : (
            error
          )}
        </div>
      )}
    </div>
  )

  return (
    <div className="input__wrapper" style={style}>
      {label ? (
        <>
          <LabelMedium className="input__label">{label}</LabelMedium>
          {renderInput()}
        </>
      ) : (
        <>{renderInput()}</>
      )}
    </div>
  )
}

export default Input
